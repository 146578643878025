<template>
  <div>
    <CButton color="primary" @click="onClick">{{ title }}</CButton>
    <CModal
      :title="title"
      :show.sync="show"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <div v-for="item in items" :key="item.key" class="mb-3">
        <h2>{{ item.label }}</h2>
        <ul>
          <li v-if="prints[item.key] && prints[item.key].all">
            <a :href="printLink(`${prints[item.key].all}`)" target="_blank">Tutti i prodotti</a>
          </li>
          <li v-if="prints[item.key] && prints[item.key].B">
            <a :href="printLink(`${prints[item.key].B}`)" target="_blank">Prodotti linea confezionato</a>
          </li>
          <li v-if="prints[item.key] && prints[item.key].F">
            <a :href="printLink(`${prints[item.key].F}`)" target="_blank">Prodotti linea frigo</a>
          </li>
        </ul>
      </div>

      <template #footer>
        <CButton color="primary" variant="outline" @click="onClose"
          >Chiudi</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "OrdersPrints",

  props: {
    title: {
      type: String,
      default: "Stampe",
    },

    prints: {
      type: Object,
      required: true,
    },

    types: {
      type: Array,
      default: () => ["wh_islands", "wh_list", "wh_orders", "wh_producer"],
    },
  },

  data() {
    return {
      show: false,
      typesMap: [
        { key: "wh_producer", label: "Piano produttori" },
        { key: "wh_list", label: "Piano ricezione ordini" },
        { key: "wh_orders", label: "Ordini da preparare" },
        { key: "wh_islands", label: "Piano consegna ordini" },
        
        
        { key: "island_orders", label: "Isola" },
        { key: "producer_products", label: "Produttore (prodotti)" },
        { key: "producer_orders", label: "Produttore (ordini)" },
        { key: "producer_orders_direct", label: "Ordini per consegna diretta" },
      ],
    };
  },

  computed: {
    items() {
      const self = this;
      return this.typesMap.filter((item) => {
        return self.types.includes(item.key);
      });
    },
  },

  methods: {
    onClick() {
      this.show = true;
    },

    onClose() {
      this.show = false;
    },

    printLink(path) {
      return `${window.env.LOONITY_BASE_API_URL}${path}`;
    },
  },
};
</script>
